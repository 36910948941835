import axios from 'axios'
import { BASE_API, TEST_BASE_API } from './env'

// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? TEST_BASE_API : BASE_API,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  // response => response,
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 xmlhttprequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  response => {
    const res = response.data
    if (typeof res.code === 'undefined') {
      return res;
    }
    if (res.code !== 200) {
      return Promise.reject(res)
    } else {
      return res.data
    }
  },
  error => {
    return Promise.reject({
      code: 500,
      msg: error,
      data: []
    })
  }
)

export default service
