<template>
  <div id="screen-view">
    <template v-if="canLook">
      <dv-full-screen-container class="screen-container">
        <top @areaChange="areaChange"></top>
        <div class="main-body">
          <div class="main-row">
            <div class="left">
              <left-top :data="data.data" :count="data.count" v-if="data.data"></left-top>
            </div>
            <div class="body" v-if="showCenter">
              <center-map :map="map"></center-map>
            </div>
            <div class="right">
              <right-top1 :data="data.type" v-if="data.type"></right-top1>
            </div>
          </div>

          <div>
            <div class="footer">
              <dv-border-box-12  style="width:100%;height:370px">
                <div style="display: flex;justify-content: space-around">
                  <footer-left :data="data.industry" v-if="data.industry"></footer-left>
                  <footer-right :data="data.fee" :fee="data.saveFee" v-if="data.fee"></footer-right>
                </div>
              </dv-border-box-12>

            </div>
          </div>
        </div>
      </dv-full-screen-container>
    </template>
    <template v-else>
      <div class="pass-view">
        <div class="pass-body">
          <input type="password" class="pass-input" v-model="password" placeholder="请输入访问密码">
          <span class="pass-msg" v-show="passwordError">密码错误</span>
          <div class="pass-btn" @click="checkPass">确 定</div>
        </div>
      </div>
    </template>
    <dv-loading v-show="showLoading" class="loading">加载中...</dv-loading>
  </div>

</template>

<script>
import top from './data/top'
import leftTop from "./data/leftTop";
import rightTop1 from "./data/rightTop1";
import Center from './data/center'
import FooterLeft from './data/footerLeft'
import FooterRight from './data/footerRight'
export default {
  name: "index",
  components: {
    top,
    leftTop,
    rightTop1,
    CenterMap: Center,
    FooterRight,
    FooterLeft
  },
  data() {
    return {
      canLook: true,
      data: {},
      password: '',
      passwordError: false,
      showCenter: false,
      map: {
        province_id: 620000,
        city_id: 620500,
        county_id: ''
      },
      timerId: null,
      showLoading: false
    }
  },
  created() {
    let center = this.getUrlParam('center');
    this.showCenter = parseInt(center) === 1;
    if (this.canLook) {
      this.getData();
    }
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId)
  },
  methods: {
    areaChange(map) {
      this.map = map;
      this.showLoading = true;
      this.getData();
    },
    getData() {
      if (this.timerId) clearTimeout(this.timerId);
      this.$ajax.get('/statistics/demand_company_city/total', {params: this.map}).then(res =>{
        this.data = res;
      }).finally(() => {
        this.showLoading = false;
        this.timerId = setTimeout(() => {
          this.getData()
        }, 5000)
      })
    },
    checkPass() {
      // let d = new Date();
      // let m = d.getMonth() + 1;
      // let dd = d.getDate();
      // let w = d.getDay();
      // if (this.password !== '' + m + dd + w) {
      //   this.passwordError = true;
      //   return;
      // }
      if (this.password !== '123456') {
        this.passwordError = true;
        return;
      }
      this.passwordError = false;
      this.canLook = true;
      this.getData();
    },
    /**
     * 获取链接参数
     * @param {String} name
     */
    getUrlParam(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    }
  }
}
</script>

<style lang="scss" scoped>
  #screen-view {
    width: 100%;
    height: 100%;
    background-color: #030409;
    color: #fff;
  }
  .screen-container {
    display: flex;
    flex-direction: column;
    background-color: #030409;
    background-image: url("../../assets/bg.110420cf.png");
    background-size: 100% 100%;
  }

  .main-body {
    flex: 1;
    flex-direction: column;
    padding: 10px 30px 0;
    display: flex;
    justify-content: space-around;
    .left {
      width: 45%;
    }
    .body {
      flex: 1;
    }
    .right {
      width: 45%;
    }
  }
  .main-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .gap {
    margin: 30px 0;
  }
  .footer {
    padding: 10px;
    display: flex;
    justify-content: space-around;
  }

  .pass-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pass-body {
    width: 300px;
    height: 400px;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pass-input {
    width: 168px;
    height: 30px;
    padding: 6px 15px;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 3px;
    background: #fefefe;
  }
  .pass-msg {
    margin-top: 10px;
    width: 200px;
    color: #ca0000;
    font-size: 12px;
    text-align: left;
  }
  .pass-btn {
    margin-top: 10px;
    width: 200px;
    padding: 12px 0;
    border-radius: 5px;
    background: #42b983;
    text-align: center;
    color: #fff;
  }
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
    z-index: 999;
    width: 100px;
    height: 100px;
  }
</style>