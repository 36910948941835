<template>
  <div class="footer-right">
    <dv-charts v-if="show" :option="options" style="height: 390px;width: 650px" />
  </div>
</template>

<script>
export default {
  name: "footerRight",
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    fee: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      options: {},
      show: false
    }
  },
  created() {
    this.setData()
  },
  mounted() {
  },
  watch: {
    data() {
      console.log('right chenage')
      this.show = false;
      this.setData();
    }
  },
  methods: {
    setData() {
      let data = [];
      let names = [];
      let max = 0;
      this.data.map(item => {
        data.push(item.value);
        names.push(item.name);
        if (max < item.value) {
          max = item.value;
        }
      })
      let fee = this.fee.map( f => f.value);

      let config = {
        title: {
          text: '各类质量服务需求市场体量预计（单位：万元）',
          style: {
            fill: '#fff',
            fontSize: 20,
            fontWeight: 'normal'
          }
        },
        grid: {
          left: 200
        },
        legend: {
          top: 65,
          right: 0,
          data: [{name: '市场体量', color: '#67e0e3'}, {name: '预估节省费用', color: '#4D77FF'}],
          textStyle: {
            fontSize: 13,
            fill: '#fff'
          }
        },
        xAxis: {
          data: 'value',
          min: 0,
          max: max,
          splitLine: {
            show: false
          },
          axisLabel: {
            style: {
              fill: '#fff'
            }
          }
        },
        yAxis: {
          data: names,
          splitLine: {
            show: false
          },
          axisLabel: {
            style: {
              fontSize: 12,
              fill: '#fff',
              rotate: 20
            }
          }
        },
        series: [
          {
            data: data,
            type: 'bar',
            name: '市场体量',
            label: {
              show: true,
              offset: [-50, 0],
              style: {
                fill: '#fff',
                fontSize: 12
              },
              formatter: (v) => {
                return (v.value / 10000).toFixed(2) + ''
              }
            },
            gradient: {
              color: ['#37a2da', '#67e0e3']
            }
          },
          {
            data: fee,
            type: 'bar',
            name: '预估节省费用',
            label: {
              show: true,
              offset: [-50, 0],
              style: {
                fill: '#fff',
                fontSize: 12
              },
              formatter: (v) => {
                return (v.value / 10000).toFixed(2) + ''
              }
            },
            gradient: {
              color: ['#4D77FF', '#00DDFF']
            }
          }
        ]
      };
      this.options = config;
      this.show = true;
    }
  }
}
</script>

<style scoped>
.footer-right {
  padding-left: 20px;
}
</style>